<template>
    <div class="page" v-title data-title="配置设备">
        <div v-show="bound">
            <div class="weui-cells__title" style="font-size: 16px;">设备信息</div>
            <div class="weui-cells" style="margin: 10px 10px; border-radius:5%;">
                <div class="weui-cell">
                    <div class="weui-cell__bd">
                        <p>设备编号</p>
                    </div>
                    <div class="weui-cell__ft">{{ info.deviceNo }}</div>
                </div>
                <div class="weui-cell">
                    <div class="weui-cell__bd">
                        <p>设备品牌</p>
                    </div>
                    <div class="weui-cell__ft">{{ info.deviceBrand }}</div>
                </div>
                <a v-on:click="showDialog(true)" class="weui-cell weui-cell_access">
                    <div class="weui-cell__bd">
                        <p>设备名称</p>
                    </div>
                    <div v-if="info.deviceName" class="weui-cell__ft">{{ info.deviceName }}</div>
                    <div v-else class="weui-cell__ft">{{ info.deviceModel }}</div>
                </a>
            </div>
            <br>
            <div class="weui-msg__opr-area">
                <p class="weui-btn-area">
                    <a v-on:click="unbind" class="weui-btn weui-btn_primary" style="width: 95%; padding: 12px 24px">解除绑定</a>
                </p>
            </div>
            <!--<div class="weui-cells__title" style="margin: 10px auto; font-size: 16px;">设备若未联网，请点击联网配置</div>
            <div class="weui-msg__opr-area">
                <p class="weui-btn-area">
                    <a v-on:click="network" class="weui-btn weui-btn_primary" style="width: 95%; padding: 12px 24px">联网配置</a>
                </p>
            </div>-->
        </div>
        <div v-show="unbound">
            <br>
            <div>
                <div class="weui-cells__title" style="margin: -10px auto; font-size: 16px;">手动添加</div>
                <input v-model="deviceNo" style="margin-left: 5%; height: 32px; width: 60%" placeholder="设备编号" />
                <a v-on:click="bind" style="top: 14px; margin-left: 30px; font-size: 18px;" class="weui-btn weui-btn_mini weui-btn_primary">确定</a>
            </div>
            <br>
            <br>
            <div class="weui-cells__title" style="margin: 10px auto; font-size: 16px;">扫描二维码</div>
            <div>
                <a v-on:click="scan" class="weui-btn weui-btn_primary" style="width: 90%; padding: 12px 24px">扫码添加</a>
            </div>
        </div>

        <div class="js_dialog" id="iosDialog1" v-show="dialog_open">
            <!--<div class="weui-mask"></div>-->
            <div id="js_dialog_1" class="weui-half-screen-dialog">
                <div class="weui-half-screen-dialog__hd">
                    <div class="weui-half-screen-dialog__hd__side">
                        <!--<button class="weui-icon-btn">关闭<i class="weui-icon-close-thin"></i></button>-->
                    </div>
                    <div class="weui-half-screen-dialog__hd__main">
                        <strong class="weui-half-screen-dialog__title">请输入设备名称</strong>
                    </div>
                </div>
                <div class="weui-half-screen-dialog__bd">
                    <div>
                        <textarea v-model="deviceName" class="weui-textarea" rows="3" ref="focusTextarea"></textarea>
                    </div>
                    <div style="height: 3em; text-align: center">
                        <div v-on:click="showDialog(false)" style="float: left; width: 50%; line-height: 3em">取消</div>
                        <div v-on:click="edit()" style="float: right; width: 50%; line-height: 3em">确认</div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import http from "@/utils/http";
    import wxmp from "@/utils/wxmp";

    export default {
        name: "Awgels_Device",
        data() {
            return {
                deviceNo: this.$route.params.deviceNo,
                info: {},
                bound: true,
                unbound: false,
                dialog_open: false,
                deviceName: ''
            }
        },
        created() {
            if (!this.deviceNo) {
                this.bound = false;
                this.unbound = true;
            }
        },
        mounted() {
            if (!this.deviceNo) {
                wxmp.wxJsapi.init();
            } else {
                http.get('/api/device/info', {
                    deviceNo: this.deviceNo
                }, res => { this.info = res.data });
            }
        },
        methods: {
            unbind() {
                http.post('/api/device/bind', {
                    deviceNo: this.deviceNo,
                    status: 0
                }, () => {
                    this.bound = false;
                    this.unbound = true;
                    wxmp.wxJsapi.init();
                });
            },
            bind() {
                if (!this.deviceNo) {
                    alert('请先输入设备编号');
                    return;
                }
                http.post('/api/device/bind', {
                    deviceNo: this.deviceNo,
                    status: 1
                }, (res) => {
                    this.info = res.data;
                    this.bound = true;
                    this.unbound = false;
                });
            },
            scan() {
                wxmp.wxJsapi.scan();
                /*wxmp.wxJsapi.scan(res => {
                    http.post('/api/device/bind', {
                        deviceNo: res.resultStr,
                        status: 1
                    }, (response) => {
                        this.bound = true;
                        this.unbound = false;
                        this.info = response.data;
                    });
                });*/
            },
            network() {
                this.$router.push({
                    name: 'Awgels_Network',
                    params: {
                        deviceNo: this.deviceNo
                    }
                })
            },
            showDialog(isShow) {
                this.dialog_open = isShow;
                if (isShow) {
                    setTimeout(() => {
                        this.$refs.focusTextarea.focus();
                    }, 500);
                    this.deviceName = this.info.deviceName ? this.info.deviceName : this.info.deviceModel;
                }
            },
            edit() {
                if (!this.deviceName) {
                    alert('请先输入设备名称');
                    return;
                }
                http.post('/api/device/edit', {
                    deviceNo: this.deviceNo,
                    deviceName: this.deviceName
                }, (res) => {
                    this.info = res.data;
                    this.dialog_open = false;
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .weui-textarea { width: 98%; border: 1px solid rgba(0, 0, 0, 0.1); font-size: 16px; }
    .weui-half-screen-dialog__hd__side{ left: 8px }
    .weui-half-screen-dialog__hd { height: 5em }
    .weui-half-screen-dialog__title { color: rgba(0,0,0,.8); font-size: 16px; font-weight: normal; color: rgba(0, 0, 0, 0.5) }
</style>
